import React from 'react';
import {Form, Row, Col} from 'react-bootstrap';
import {useStaticQuery, graphql} from 'gatsby';
import {useFormContext,useForm} from 'react-hook-form';
import moment from 'moment';


const Bike = () => {

	const { register, watch, formState: { errors } } = useFormContext() || useForm();


	const query = useStaticQuery(graphql`
		{
			allMakesJson {
				nodes {
					make
					jsonId
				}
			}
			allModelsJson {
				nodes {
					year
					make_id
					model
					jsonId
				}
			}
		}
	`);

	const allModels = query.allModelsJson.nodes;
	const allMakes = query.allMakesJson.nodes;

	let todayYear = moment().year();

	const filters = watch(['yearOfBike', 'make']);

	//console.log(allModels)
	return (
		<>
			<Row className="mb-3">
				<Form.Group as={Col} lg={3} controlId='yearOfBike'>
					<Form.Label>Year</Form.Label>

					<Form.Select
						isInvalid={errors.yearOfBike}
						{...register('yearOfBike')}
					>
						{[...Array(30)].map((el, i) => {
							return <option key={todayYear-i}>{todayYear-i}</option>;
						})}
					</Form.Select>
					{errors.yearOfBike && (
						<Form.Control.Feedback type='invalid'>
							{errors.yearOfBike.message}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<Col lg={9}>
					<Form.Group controlId='make'>
						<Form.Label>Make</Form.Label>
						<Form.Select isInvalid={errors.make} {...register('make')}>
							<option value=''> -- Please select -- </option>
							{allMakes.map(({make, jsonId}, i) => {
								return (
									<option value={jsonId} key={jsonId}>
										{make}
									</option>
								);
							})}
						</Form.Select>
						{errors.make && (
							<Form.Control.Feedback type='invalid'>
								{errors.make.message}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				</Col>
			</Row>
			<Row className='mb-3'>
				<Form.Group as={Col} controlId='model'>
					<Form.Label>Model</Form.Label>
					<Form.Select isInvalid={errors.model} {...register('model')}>
						{
							!(filters[0]!==undefined && !!filters[1]) &&
							<option value=''> Please select Year of Bike and Make. </option>
						}
						{
							filters[0]!==undefined && !!filters[1] &&
							<option value=''>-- Please select --</option>
						}
						{allModels.map(({make_id, year, model, jsonId}, index) => {
							if (make_id == filters[1] && year == filters[0]) {
								return (
									<option value={model} key={jsonId}>
										{model}
									</option>
								)
							}
							else return null
						})}
					</Form.Select>
					{errors.model && (
						<Form.Control.Feedback type='invalid'>
							{errors.model.message}
						</Form.Control.Feedback>
					)}
				</Form.Group>
			</Row>
		</>
	);
};

export default Bike;
